#about {
  margin-bottom: 150px;
}

.about-heading {
  text-align: center;
  margin-bottom: 70px;
}

.about-pic {
  margin: 0 auto;
}

.aboutimg {
  width: 100%;
  border-radius: 20px;
  background-color: var(--color-primary);
}

.about-cards {
  justify-content: center;
  align-items: center;
}

.about-card {
  text-align: center;
  padding: 10px 5px;
  border: 1px solid white;
  border-radius: 20px;
  margin: 15px;
}

.about-card:hover {
  background-color: var(--color-primary);
  color: black;
}

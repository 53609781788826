nav {
  background-color: rgba(29, 29, 29, 0.76);
  width: max-content;
  padding: 15px 25px;
  position: fixed;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  display: flex;
  gap: 20px;
  border-radius: 25px;
}

nav a {
  background: transparent;
  padding: 8px;
  margin: 0 5px;
  color: var(--color-light);
  font-size: 18px;
  border-radius: 40%;
}

nav a:hover {
  background-color: rgba(0, 0, 0, 0.568);
}

nav a.active {
  background-color: black;
  color: white;
}

@media screen and (max-width: 768px) {
  nav {
    padding: 10px 15px;
  }
  nav a {
    padding: 6px;
    margin: 0 6px;
  }
}

@media screen and (max-width: 576px) {
  nav {
    padding: 3px 6px;
  }
  nav a {
    padding: 4px;
    margin: 0 4px;
  }
}

#footer {
  margin-bottom: 120px;
}

.permalinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;

  padding: 0;
  margin: 0;
}

.permalinks li a {
  text-decoration: none;
}

.footer-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.footer-socials a {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  #footer {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 340px) {
  .permalinks {
    gap: 15px;
  }
  .permalinks li {
    display: block;
    width: 100%;
    text-align: center;
  }
}

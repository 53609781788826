#experience {
  margin-bottom: 150px;
}

.experience-heading {
  margin-bottom: 70px;
}

.experience-body {
  justify-content: space-between;
}

.frontend,
.backend {
  border: 2px solid var(--color-primary);
  border-radius: 25px;
  background-color: #212529;
  color: white;
  padding: 0 25px;
}

.langus {
  text-align: left;
  margin-bottom: 30px;
}
.langus i {
  margin-right: 10px;
  font-size: 10px;
}

.langus small {
  margin-left: 20px;
}

@media screen and (max-width: 992px) {
  .langus {
    text-align: center;
  }
  .langus li {
    font-weight: bold;
    font-size: 16px;
  }
  .langus i {
    display: none;
  }

  .langus small {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .frontend {
    margin-bottom: 50px;
  }

  .langus {
    text-align: center;
  }
  .langus li {
    font-weight: bold;
    font-size: 16px;
  }
  .langus i {
    display: none;
  }

  .langus small {
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .langus {
    text-align: center;
  }
  .langus li {
    font-weight: bold;
    font-size: 16px;
  }
  .langus i {
    display: none;
  }

  .langus small {
    margin: 0;
  }
}

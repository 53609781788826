header {
  margin: 100px 0 150px 0;
}
.header_container {
  text-align: center;
}

.cta {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 25px;
}

.header_socials {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.header_socials a {
  font-size: 30px;
}

.me {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border-radius: 25px;
}

.me img {
  width: 42%;
  background-color: var(--color-primary);
  border-radius: 25px;
  height: 102%;
}

@media screen and (max-width: 992px) {
  .me img {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .header_container {
    margin: 30px 0;
  }
  header h1 {
    font-size: 32px;
  }
  .me img {
    width: 85%;
  }
}

@media screen and (max-width: 576px) {
  header h1 {
    font-size: 28px;
  }
  .me img {
    width: 95%;
    height: 102%;
  }

  .cta .btn {
    padding: 15px 10px;
  }
}

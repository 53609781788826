#services {
  margin-bottom: 150px;
}

.service-heading {
  margin-bottom: 70px;
}
.service-cards {
  justify-content: space-between;
  align-items: center;
}

.service-card {
  padding: 0;
  border: 2px solid var(--color-primary);
  border-radius: 0 0 20px 20px;
}

.lists {
  border-radius: 0 0 20px 20px;
  text-align: center;
  background-color: #212529;
}
.service-card li {
  padding: 25px;
}

.service-card i {
  margin-right: 15px;
}

.service-card-header {
  text-align: center;
  padding: 20px;
  background-color: var(--color-primary);
}

@media screen and (max-width: 1200px) {
  .service-card:nth-of-type(2) {
    margin: 40px 0;
  }
}

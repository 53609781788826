#portfolio {
  margin-bottom: 150px;
}

.portfolio-heading {
  margin-bottom: 70px;
  text-align: center;
}

.portfolio-body {
  justify-content: space-evenly;
  gap: 60px;
}

.portfolio-item {
  border: 2px solid var(--color-primary);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  transition: all 400ms ease;
}

.portfolio-pic img {
  border-radius: 10px;
}
.portfolio-title {
  text-align: center;
  word-wrap: break-word;
  margin: 15px 0;
}

.portfolio-links a {
  text-decoration: none;
  background-color: var(--color-primary);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
}

.portfolio-links :first-child {
  margin-bottom: 10px;
}

.portfolio-item:hover {
  transform: scale(1.05);
}

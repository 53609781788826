#testimonials {
  margin-bottom: 150px;
}
.testimonial-heading {
  margin-bottom: 70px;
  text-align: center;
}

.testimonial-body {
  padding: 30px;
  border-radius: 20px;
}
.client-pic {
  width: 12%;
  border-radius: 50%;
  margin: 0 auto;
  border: 3px solid var(--color-primary);
  margin-bottom: 30px;
}
.client-pic img {
  width: 100%;
  border-radius: 50%;
}
.client {
  margin-bottom: 30px;
}

.client-review {
  display: block;
  margin-bottom: 30px;
  font-style: italic;
}

.swiper {
  border-radius: 20px;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: var(--color-primary);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: var(--color-primary);
}

@media screen and (max-width: 1200px) {
  .client-pic {
    width: 15%;
  }
}

@media screen and (max-width: 992px) {
  .client-pic {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .client-pic {
    width: 30%;
  }
}

@media screen and (max-width: 576px) {
  .client-pic {
    width: 40%;
  }
}

@media screen and (max-width: 300px) {
  .client-pic {
    width: 50%;
  }
}

#contact {
  margin-bottom: 150px;
}

.contact-heading {
  text-align: center;
  margin-bottom: 70px;
}

.contact-body {
  justify-content: space-around;
}

.emw {
  display: block;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 60px;
}
.contact-options {
  padding: 20px;
  border: 2px solid #4db5ff;
  text-align: center;
  border-radius: 30px;
  background-color: #212529;
}

.contact-options i {
  font-size: 24px;
  color: #4db5ff;
}

.contact-options h4,
.contact-options h5 {
  margin: 16px 0;
}

.contact-options a {
  text-decoration: none;
}

.contact-options:nth-of-type(2n) {
  margin: 30px 0;
}

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form form {
  width: 100%;
}
input,
textarea {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 15px;
}

input:focus,
textarea:focus {
  box-shadow: 0px 0px 8px 0.5px #4db5ff;
}

#email {
  margin: 30px 0;
}

@media screen and (max-width: 400px) {
  .contact-options h4 {
    font-size: 18px;
  }
  .contact-options h5,
  .contact-options a {
    font-size: 15px;
  }
}

@media screen and (max-width: 300px) {
  .contact-options h4 {
    font-size: 16px;
  }
  .contact-options h5,
  .contact-options a {
    font-size: 13px;
  }
}
